import { BRANCHES } from "./branches";
import { CURRENTMONTH, CURRENTYEAR, MONTHS, YEARS } from "./date";
import { POSITIONS } from "./positions";
import { ADD_DESCRIPTION, ADD_TITLE, 
    ARCHIVE_DESCRIPTION, ARCHIVE_TITLE, 
    DELETE_DESCRIPTION, DELETE_TITLE, 
    EDIT_DESCRIPTION, EDIT_TITLE, 
    RETRIEVE_DESCRIPTION, RETRIEVE_TITLE } from "./successful-action-text";

const DOMAIN = 'http://localhost:80/bjmppos/api/' ;
//const DOMAIN = 'https://bjmpro3pos.com/api/' ;
const SECUGEN = 'https://localhost:8443/';
const TABLE_BIG_CONTENT = 20;
const TABLE_MEDIUM_CONTENT = 10;
const TABLE_SMALL_CONTENT = 5;
export {
    DOMAIN,
    SECUGEN,
    BRANCHES,
    POSITIONS,
    TABLE_BIG_CONTENT,
    TABLE_MEDIUM_CONTENT,
    TABLE_SMALL_CONTENT,
    ADD_TITLE, ADD_DESCRIPTION,
    EDIT_TITLE, EDIT_DESCRIPTION,
    ARCHIVE_TITLE, ARCHIVE_DESCRIPTION,
    RETRIEVE_TITLE, RETRIEVE_DESCRIPTION,
    DELETE_TITLE, DELETE_DESCRIPTION, 
    MONTHS, YEARS, CURRENTMONTH, CURRENTYEAR
}
